import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import pvc from "../images/electrical/pvc.png";
import socket from "../images/electrical/socket.png";
import wirecable from "../images/electrical/wire-cable.png";
import metrebox from "../images/electrical/metrebox.png";


function ElectricalPage() {
    return (
      <Layout>
        <SEO
          title="Building"
          keywords={[`building`, `cement`, `Zimbabwe`, `bricks`]}
        />
  
       
            <div class="rounded overflow-hidden ">
              <img src={pvc}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">PVC Conduit</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={wirecable}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Wire Cable</div>
               <p class="text-gray-700 text-base">
               
               </p>
             
             
            
          
            <div class="rounded overflow-hidden shadow-lg">
              <img src={socket}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Double Socket Outlet</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             <img src={metrebox}  alt="Sunset in the mountains"/>
             
               <div class="font-bold text-xl mb-2">Metre Box</div>
               <p class="text-gray-700 text-base">
              
               </p>
             

         
             


            
               <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>
          
         
             
        

         
            
         
            
         
        
      </Layout>
    );
  }
  
  export default ElectricalPage;
  